import useSWR from "swr";
import axios from "@/lib/axios";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import cookieCutter from "cookie-cutter";
import useLoader from "@/hooks/loader";
import {
    convertToFormData,
    deregisterAllServiceWorkers,
    userRole,
} from "@/utilities";
import { useAnalytics } from "@/hooks/analytics";
import { analyticsEvents, guestRoutes } from "@/constants";
import { useDispatch } from "react-redux";
import { authUser } from "@/services/slices/usersSlice";
import axiosAuth from "@/lib/axiosAuth";
import axiosMeet from "@/lib/axiosMeet";

export const useAuth = ({ middleware, redirectIfAuthenticated } = {}) => {
    const loader = useLoader();
    const router = useRouter();
    const dispatch = useDispatch();
    const { analytics } = useAnalytics();
    let token = null;
    let destination_path = null;

    // Flag to execute tracking only when the login/register function is called the first time
    const [firstLogin, setFirstLogin] = useState(false);
    const [firstLogout, setFirstLogout] = useState(false);
    const [firstRegister, setFirstRegister] = useState(false);
    const {
        data: user,
        error,
        revalidate,
    } = useSWR(
        "/api/user",
        async () => {
            const token = cookieCutter.get("accessToken");
            if (!token) {
                throw new Error("No token found");
            }
            try {
                const response = await axiosAuth.get('/api/users');
                const userData = response.data;

                if (!userData.allowedOnWebApp) {
                    window.location = process.env.NEXT_PUBLIC_BACKEND_URL;
                    return;
                }
                dispatch(authUser(userData));
                return userData;
            } catch (error) {
                if (error.response?.status === 401) {
                    if (error.response?.data?.message === "TokenExpired") {
                        await handleAuthExpiredAccessToken();
                        return axiosAuth.get('/api/users').then(res => res.data);
                    } else {
                        await logout({ message: "sessionExpired" });
                        return;
                    }
                }
                if (error.response?.status !== 409) {
                    throw error;
                }
                router.push("/verify-email");
            }
        },
        {
            revalidateOnFocus: false,
            refreshInterval: 1800000,
            refreshWhenHidden: true,
        }
    );

    const organization = user?.organization;
    const register = async ({ setErrors, setNotification, ...props }) => {
        setErrors([]);
        loader.start();
        axiosAuth
            .post("/api/auth/register", props)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setFirstRegister(true);
                }
                const uuid = response?.data?.user?.uuid;
                const organizationId = response?.data?.user?.organizationId;
                props.uuid = uuid;
                props.organization_id = organizationId;
                return {
                    uuid,
                    organizationId,
                    tokens: {
                        accessToken: response?.data?.accessToken,
                        refreshToken: response?.data?.refreshToken,
                    },
                };
            })
            .then((data) => {
                return axios
                    .post("/register", convertToFormData(props))
                    .then((response) => {
                        const { accessToken, refreshToken } = data.tokens;
                        cookieCutter.set("accessToken", accessToken, {
                            path: "/",
                            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                            secure: true,
                        });
                        cookieCutter.set("refreshToken", refreshToken, {
                            path: "/",
                            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                            secure: true,
                        });
                        setNotification({
                            message: "Registration successful.",
                            type: "success",
                        });
                        setTimeout(() => setNotification(null), 5000);
                        
                        setFirstLogin(true);
                        setFirstLogout(false);
                        revalidate();
                    });
            })
            .catch((error) => {
                setErrors([error.response?.data.error]);
            })
            .finally(() => loader.done());
    };

    const login = async ({ setErrors, setStatus, ...props }) => {
        setErrors([]);
        setStatus(null);
        loader.start();
        axiosAuth
            .post("/api/auth/login", props.payload)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    setFirstLogin(true);
                    setFirstLogout(false);
                    cookieCutter.set("accessToken", response.data.accessToken, {
                        path: "/",
                        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                        secure: true,
                    });
                    cookieCutter.set("refreshToken", response.data.refreshToken, {
                        path: "/",
                        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                        secure: true,
                    });
                }
                revalidate();
            })
            .catch((error) => {
                setErrors([error.response?.data.message]);
            })
            .finally(() => {
                loader.done();
            });
    };

    const googleLogin = ({ setErrors, setStatus, ...props }) => {
        setErrors([]);
        setStatus(null);
        loader.start();

        axiosAuth
            .post("/api/auth/google-login", props)
            .then((response) => {
                if (response.status === 200) {
                    setFirstRegister(true);
                }
                cookieCutter.set("accessToken", response.data.accessToken, {
                    path: "/",
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                    secure: true,
                });
                cookieCutter.set("refreshToken", response.data.refreshToken, {
                    path: "/",
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                    secure: true,
                });
                if (response.data?.user) {
                    // if the new user then register candidate
                    const candidateCreate = {
                        'uuid': response.data.user.uuid,
                        'organization_id' : response.data.user.organizationId
                    }
                    return axios.post("/register", convertToFormData(candidateCreate));
                }
                return Promise.resolve();
            })
            .then((response) => {
                revalidate();
            })
            .catch((error) => {
                setErrors([error.response?.data.error]);
            })
            .finally(() => loader.done());
    };

    const forgotPassword = async ({ setErrors, setStatus, email }) => {
        setErrors([]);
        setStatus(null);
        loader.start();

        const encodedEmail = encodeURI(email);
        axiosAuth
            .post("/api/auth/forgot-password", { email: encodedEmail })
            .then((response) => setStatus(response?.data?.message))
            .catch((error) => {
                setErrors([error.response?.data.message]);
            })
            .finally(() => loader.done());
    };

    const resetPassword = async ({ setErrors, setStatus, ...props }) => {
        setErrors([]);
        setStatus(null);
        loader.start();

        axiosAuth
            .post("/api/auth/reset-password", { token: router.query.token, ...props })
            .then((response) => {
                setStatus(response?.data?.message || "Password reset successfully")
                router.push("/login")
            })
            .catch((error) => {
                setErrors([error.response?.data.message]);
            })
            .finally(() => loader.done());
    };

    const forceResetPassword = async ({ setErrors, setStatus, ...props }) => {
        setErrors([]);
        setStatus(null);
        loader.start();

        axiosAuth
            .post("/api/auth/force-reset-password", {
                ...props,
            })
            .then(() => router.push("/company/dashboard"))
            .catch((error) => {
                setErrors([error.response?.data.message]);
            })
            .finally(() => loader.done());
    };

    const forcedUpdateDetails = async ({ setErrors, setStatus, ...props }) => {
        setErrors([]);
        setStatus(null);
        loader.start();

        axiosAuth
            .post("/api/users/force-update-details", {
                ...props,
            })
            .then(() => {
                if (user.is_onboarding_completed) {
                    router.push("/engineer/interviews");
                } else {
                    router.push(
                        `/engineer/onboarding?stage=${user?.onboarding_step}`
                    );
                }
            })
            .catch((error) => {
                setErrors([error.response?.data.message]);
            })
            .finally(() => loader.done());
    };

    const resendEmailVerification = ({ setStatus }) => {
        loader.start();

        // TODO: need to figure it out where it is used
        axios
            .post("/email/verification-notification")
            .then((response) => setStatus(response?.data?.message))
            .finally(() => loader.done());
    };

    const logout = async (...props) => {
        const { message = "" } = props[0] || {};
        if (!error) {
            loader.start();
            setFirstLogout(true);
            cookieCutter.set("accessToken", "", {
                expires: new Date(0),
                path: "/",
                domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                secure: true
            });
            cookieCutter.set("refreshToken", "", {
                expires: new Date(0),
                path: "/",
                domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                secure: true
            });

            dispatch(authUser(undefined));
            localStorage.clear();
            deregisterAllServiceWorkers();
            let queryParam = "";
            if (message === 'sessionExpired'){
                queryParam = '?sessionexpired=1';
            }
            window.location.href = `${process.env.NEXT_PUBLIC_SITE_URL}/login${queryParam}`;
            loader.done();
        }
    };

    const handleAuthExpiredAccessToken = async () => {
        const refreshToken = cookieCutter.get("refreshToken");
        loader.start();
        if (!refreshToken) {
            await logout({ message: "sessionExpired" });
            return;
        }

        try {
            loader.start();
            const response = await axiosAuth.post("/api/auth/refresh-token", {
                refreshToken: refreshToken,
            });

            if (response.status === 200) {
                cookieCutter.set("accessToken", response.data.accessToken, {
                    path: "/",
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                    secure: true,
                });

                if (response.data.refreshToken) {
                    cookieCutter.set("refreshToken", response.data.refreshToken, {
                        path: "/",
                        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                        secure: true,
                    });
                }
                revalidate();
            } else {
                await logout({ message: "sessionExpired" });
            }
        } catch (error) {
            await logout({ message: "sessionExpired" });
        } finally {
            loader.done();
        }
    };

    useEffect(async () => {
        destination_path = cookieCutter.get("destination_path");

        token = cookieCutter.get("accessToken");
        if (
            !token &&
            middleware !== "guest" &&
            !guestRoutes.includes(router?.pathname)
        ) {
            await router.push("/login");
            cookieCutter.set(
                "destination_path",
                router?.asPath ?? window.location.pathname,
                {
                    path: "/",
                }
            );
        }
    }, []);

    // tracking effect function runs only when user logs in
    useEffect(async () => {
        if (user) {
            let roles = [];
            user.roles.forEach((e) => {
                roles.push(e.name);
            });
            const urlParams = new URLSearchParams(window.location.search);
            const share_code = urlParams.get("share_code");
            const referral_code = urlParams.get("referral_code");

            let traits = {
                name: user.name,
                email: user.email,
                id: user.id,
                roles: roles,
                share_code: share_code,
                referral_code: referral_code,
                company: {
                    id: user.organization?.id,
                    name: user.organization?.name,
                },
                // HotJar does not support
                role_list: roles?.join(","),
                company_id: user.organization?.id,
                company_name: user.organization?.name,
            };
            let options = {
                Intercom: {
                    user_hash: user.intercom_user_hash,
                },
            };
            await analytics.identify(user.id, traits, options);

            if (firstLogin) {
                await analytics.track(analyticsEvents.USER_LOGGED_IN, traits);
                setFirstLogin(false);
            }
        }
    }, [user, firstLogin]);

    useEffect(async () => {
        if (user) {
            await analytics.page(document.title);
        }
    }, [user]);

    useEffect(async () => {
        if (user && firstLogout) {
            let traits = {
                name: user.name,
                email: user.email,
                id: user.id,
                company: {
                    id: user.organization?.id,
                    name: user.organization?.name,
                },
            };
            await analytics.track(analyticsEvents.USER_LOGGED_OUT, traits);
            await analytics.reset();
            setFirstLogout(false);
        }
    }, [user, firstLogout]);

    useEffect(async () => {
        if (user && firstRegister) {
            let roles = [];
            user.roles.forEach((role) => roles.push(role.name));

            const urlParams = new URLSearchParams(window.location.search);
            const share_code = urlParams.get("share_code");
            const referral_code = urlParams.get("referral_code");
            const type = urlParams.get("type");

            let traits = {
                name: user.name,
                email: user.email,
                id: user.id,
                roles: roles,
                share_code: share_code,
                referral_code: referral_code,
                type: type,
                company: {
                    id: user.organization?.id,
                    name: user.organization?.name,
                },
                // HotJar does not support
                role_list: roles?.join(","),
                company_id: user.organization?.id,
                company_name: user.organization?.name,
            };
            let options = {
                Intercom: {
                    user_hash: user.intercom_user_hash,
                },
            };
            await analytics.identify(user.id, traits, options);
            await analytics.track(analyticsEvents.USER_SIGNUP, traits);
            setFirstRegister(false);
        }
    }, [user, firstRegister]);

    useEffect(async () => {
        const { userIsCompany, userIsEngineer } = userRole(user);

        if (middleware === "guest" && redirectIfAuthenticated && user) {
            if (user.missing_details) {
                await router.push("/force-add-details");
            } else if (user.force_reset_password) {
                await router.push("/force-reset-password");
            } else {
                if (userIsCompany) {
                    await router.push(redirectIfAuthenticated.company);
                } else if (userIsEngineer) {
                    if (user.is_onboarding_completed) {
                        await router.push("/engineer/interviews");
                    } else {
                        await router.push(
                            `/engineer/onboarding?stage=${user?.onboarding_step}`
                        );
                    }
                } else {
                    await router.push(redirectIfAuthenticated.fallback);
                }
            }
        }

        if (middleware === "auth") {
            const token = cookieCutter.get("accessToken");
            const refreshToken = cookieCutter.get("refreshToken");
            if (!token || !refreshToken) {
                await logout();
            }
            if (user) {
                if (destination_path) {
                    await router.push(destination_path);
                    cookieCutter.set("destination_path", null, {
                        expires: new Date(0),
                        path: "/",
                    });
                }
                if (user.missing_details) {
                    await router.push("/force-add-details");
                } else if (user.force_reset_password) {
                    await router.push("/force-reset-password");
                } else {
                    const moduleName = router.pathname.split("/")[1];
                    const openModules = ["force-reset-password"];
                    if (!openModules.includes(moduleName)) {
                        const companyModule = "company";
                        const engineerModule = "engineer";

                        if (companyModule === moduleName && !userIsCompany) {
                            if (userIsEngineer) {
                                await router.push("/engineer/profile");
                            } else {
                                await logout();
                            }
                        }

                        if (engineerModule === moduleName) {
                            if (!userIsEngineer) {
                                if (userIsCompany) {
                                    await router.push("/company/dashboard");
                                } else {
                                    await logout();
                                }
                            } else {
                                if (!user.is_onboarding_completed) {
                                    await router.push(
                                        `/engineer/onboarding?stage=${user?.onboarding_step}`
                                    );
                                } else {
                                    if (
                                        router.pathname.split("/")[2] ===
                                        "onboarding"
                                    ) {
                                        await router.push(
                                            "/engineer/interviews?is_onboarded=true"
                                        );
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                if (router?.pathname !== "/404" && !token) {
                    cookieCutter.set(
                        "destination_path",
                        router?.asPath ?? window.location.pathname,
                        {
                            path: "/",
                        }
                    );
                }
            }

            if (error) {
                await logout();
            }
        }
    }, [user, error]);

    return {
        user,
        organization,
        register,
        login,
        forgotPassword,
        resetPassword,
        forceResetPassword,
        resendEmailVerification,
        logout,
        revalidate,
        googleLogin,
        forcedUpdateDetails,
        handleAuthExpiredAccessToken,
    };
};
